import React from 'react'
import Layout from '../components/Layout/Layout'

const PageNotFound = () => (
  <Layout pageTitle="404" headerImage={2}>
    <div className="container container--page">
      <h1>Nie znaleziono strony</h1>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </Layout>
)

export default PageNotFound
